
import { computed, defineComponent, reactive } from "vue";
import { useHead } from "@vueuse/head";
import { useStore } from "vuex";

import CSpinner from "@/components/spinner.vue";
import CHistoricCard from "@/views/historic/card.vue";
import CHistoricDetailCard from "@/views/historic/detail-card.vue";

import { formatDate, formatHours, formatPhone, addMoneyMask } from "@/utils/formatters";
import { Appointment } from "@/store/user/appointment/types";
import { useRoute, useRouter } from "vue-router";
import { useFilters } from "@/utils/mixins";
import { getHistoricStatus } from "@/utils";

const VHistoric = defineComponent({
  name: "VHistoric",
  components: { CSpinner, CHistoricCard, CHistoricDetailCard },
  setup() {
    useHead({ title: "Meu histórico | obmed" });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilters();

    const appointments = computed(() => store.state.user.appointment.list);
    const groupedAppointments = computed(() => store.getters.pastAppointmentsGrouped);
    const loading = reactive({ list: false, print: 0 });

    async function printElectronicGuide(appointment: Appointment) {
      const _id = appointment.guiaeletronica_set[0]?.id;
      const response = await store.dispatch("getElectronicGuide", { _id });
      window.open(URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" })));
    }

    async function downloadElectronicGuide(appointment: Appointment) {
      loading.print = appointment.id;

      const _id = appointment.guiaeletronica_set[0]?.id;
      const response = await store.dispatch("getElectronicGuide", { _id });

      const linkEl = document.createElement("a");
      linkEl.href = URL.createObjectURL(new Blob([response?.data], { type: "application/pdf" }));
      linkEl.setAttribute("download", `guia_${_id}.pdf`);
      linkEl.click();

      loading.print = 0;
    }

    async function getAppointments() {
      loading.list = true;
      await store.dispatch("getAppointments");
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "historic-filter", query: route.query });
    else getAppointments();

    return {
      ...{ appointments, groupedAppointments, loading, hasFilters },
      ...{ printElectronicGuide, downloadElectronicGuide },
      ...{ formatDate, formatHours, formatPhone, addMoneyMask, getHistoricStatus },
    };
  },
});

export default VHistoric;
