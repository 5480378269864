
import { defineComponent, PropType } from "vue";
import CDropdown, { DropdownItem } from "@/components/dropdown.vue";

const CHistoricDetailCard = defineComponent({
  name: "CHistoricDetailCard",
  components: { CDropdown },
  props: {
    itemID: { type: Number, required: true },
    loading: { type: Object as PropType<{ _id: boolean }>, default: () => ({}) },
    useOptions: { type: Boolean, default: true },
  },
  setup(_, { emit }) {
    const guiaDropdownItems: DropdownItem[] = [
      {
        label: "Visualizar",
        icon: "fas fa-receipt",
        command() {
          emit("click:print-guide");
        },
      },
      {
        label: "Baixar",
        icon: "fas fa-file-download",
        command() {
          emit("click:download-guide");
        },
      },
    ];

    return { guiaDropdownItems };
  },
});

export default CHistoricDetailCard;
